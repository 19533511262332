<template>
  <div class="register">
    <div class="register-header">
      <div class="container">
        <div class="logo">
          <img @click="$router.push('/')" src="@/assets/logo/logo.png" height="20" alt="" />
        </div>
      </div>
    </div>
    <div class="container">
      <el-card class="register-card" :body-style="{ padding: '0px' }">
        <img src="@/assets/register.jpg" class="image">
        <div class="register-content">

          <h2 class="title">创建一个FIT2CLOUD帐户</h2>
          <p>注册并使用该FIT2CLOUD帐户访问FIT2CLOUD的所有应用、社区、支持等内容。</p>
          <p>
            FIT2CLOUD将收集您的联系方式和帐户信息，以创建您的FIT2CLOUD帐户。我们使用您的个人数据来识别您的身份并为您提供信息，支持和客户服务。有关更多信息，请参见FIT2CLOUD的隐私声明。
          </p>
          <el-form label-position="top" :model="ruleForm" :rules="rules" ref="ruleForm">
            <h3>登录信息</h3>
            <el-form-item label="用户名（FIT2CLOUD ID）：" prop="userName">
              <el-input v-model="ruleForm.userName"></el-input>
            </el-form-item>
            <el-form-item label="密码：" prop="pass">
              <el-input v-model="ruleForm.pass"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="checkPass">
              <el-input v-model="ruleForm.checkPass"></el-input>
            </el-form-item>
            <h3>个人信息</h3>
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="电子邮箱：" prop="email">
              <el-input v-model="ruleForm.email"></el-input>
            </el-form-item>
            <el-form-item label="电话号码：" prop="phoneNumber">
              <el-input v-model="ruleForm.phoneNumber">
                <el-button @click="getCode" type="text" slot="suffix" class="code"
                  :disabled="!ruleForm.phoneNumber||!sendAuthCode">
                  <span v-if="sendAuthCode">获得验证码</span>
                  <span v-else>{{authTime}}s 后重新获得</span>
                </el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="验证码：" prop="code">
              <el-input v-model="ruleForm.code"></el-input>
            </el-form-item>
            <el-form-item style="text-align: center;">
              <p style="margin-top: 10px;">
                <el-button @click="submitForm" type="primary" size="medium" style="width: 150px">
                  立即创建</el-button>
              </p>
              <p style="margin-top: 10px;">
                <el-button @click="$router.push('/')" type="text" icon="el-icon-d-arrow-left">返回社区首页
                </el-button>
              </p>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
    <app-footer />
  </div>
</template>

<script>
import AppFooter from "@/components/layout/AppFooter";
export default {
  name: "Register",
  components: {
    AppFooter,
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        userName: "",
        pass: "",
        checkPass: "",
        name: "",
        email: "",
        phoneNumber: "",
        code: "",
      },
      rules: {
        userName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        pass: [{ required: true, validator: validatePass, trigger: "blur" }],
        checkPass: [
          { required: true, validator: validatePass2, trigger: "blur" },
        ],
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
        phoneNumber: [
          { required: true, message: "请输入电话", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      // 验证码
      sendAuthCode: true /*布尔值，通过v-if控制显示‘获取按钮’还是‘倒计时’ */,
      authTime: 0 /*倒计时 计数器*/,
    };
  },
  methods: {
    submitForm() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 验证
    getCode() {
      this.sendAuthCode = false;
      this.authTime = 60;
      let authTimetimer = setInterval(() => {
        this.authTime--;
        if (this.authTime <= 0) {
          this.sendAuthCode = true;
          clearInterval(authTimetimer);
        }
      }, 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  display: flex;
  flex-direction: column;
  height: 100%;
  .register-header {
    background: #202942;
    padding: 10px 0;
  }
  .register-card {
    max-width: 700px;
    margin: 30px auto;
    line-height: 25px;
    .image {
      width: 100%;
    }
    .register-content {
      padding: 15px 25px;

      .title {
        margin: 10px 0;
      }
      h3 {
        margin: 20px 0 15px;
      }
    }
  }
  .code {
    margin-right: 5px;
  }
}
</style>
